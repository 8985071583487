<template>
  <div class="debug">
    <h2>debug: page list</h2>
    <ul class="page-list">
      <li><router-link :to="`/${locale}`">1-1,2-3 TOPページ</router-link></li>
      <li><router-link :to="`/${locale}/theme/${themeId}/series`">3-1 シリーズ選択ページ</router-link></li>
      <li><router-link :to="`/${locale}/theme/${themeId}/series/101`">4-1 英雄一覧ページ</router-link></li>
      <li><router-link :to="`/${locale}/theme/${themeId}/series/101/101042`">5-1 英雄投票ページ, 8-1 投票済みページ</router-link></li>
      <li><router-link :to="`/${locale}/theme/${themeId}/series/101/101042/voted`">7-1,2 投票完了ページ</router-link></li>
      <li><router-link :to="`/${locale}/mypage`">9-1 マイページ</router-link></li>
      <li><router-link :to="`/${locale}/result`">10-1 結果発表ページ</router-link></li>
      <li><router-link :to="`/${locale}/result/1`">11-1 結果発表ページ 1-100位</router-link></li>
      <li><router-link :to="`/${locale}/error`">14-1 エラーページ</router-link></li>
    </ul>
    <div class="locale">
      <p>debug: locale is "{{ $t('locale') }}" now</p>
      <div>
        <span v-for="(item, i) in localeList" :key="i" @click="changeLocale(item.value.name)">{{ item.value.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Api from '@libraries/api/'
import RequestTitles from '@requests/titles'
import RequestHeroes from '@requests/heroes'
import { locales } from '@/constants/locale'

export default {
  name: 'Debug',
  computed: {
    ...mapGetters('theme', ['currentTheme']),
    locale () {
      return this.$route.params.lang ? this.$route.params.lang : 'ja-jp' 
    },
    localeList() {
      return locales
    },
    themeId() {
      return this.currentTheme && this.currentTheme.id ? this.currentTheme.id : 1
    }
  },
  data() {
    return {
      heroes: [],
      titles: [],
    }
  },
  watch: {
    '$route.params.lang'() {
      this.apiDebug()
    }
  },
  created() {
    this.apiDebug()
  },
  methods: {
    /* eslint-disable */
    apiDebug() {
      Api.call(new RequestTitles({locale: this.locale}))
        .then((titles) => {
          console.log('debug:title', this.locale, titles)
        })
        .catch(({ error }) => {
          throw error
        })
      Api.call(new RequestHeroes({locale: this.locale}))
        .then((heroes) => {
          console.log('debug:heroes', this.locale, heroes)
        })
        .catch(({ error }) => {
          throw error
        })
    },
    /* eslint-enable */
    changeLocale(locale) {
      this.$router.replace({ name: this.$route.name, params: {lang: locale} }, ()=>{})
    }
  },
}
</script>

<i18n>
ja-jp:
  locale: "ja-jp"
en-us:
  locale: "en-us"
en-gb:
  locale: "en-gb"
de-de:
  locale: "de-de"
fr-fr:
  locale: "fr-fr"
fr-ca:
  locale: "fr-ca"
es-es:
  locale: "es-es"
it-it:
  locale: "it-it"
es-mx:
  locale: "es-mx"
pt-br:
  locale: "pt-br"
zh-tw:
  locale: "zh-tw"
</i18n>

<style lang="scss" scoped>
.debug {
  padding: 60px;
}
.debug > * {
  font-size: 18px!important;
}
.page-list {
  li {
    margin-bottom: 10px;
  }
  a {
    text-decoration: underline;
    color: #00f;
  }
}
.locale {
  margin-top: 60px;
  div {
    display: flex;
    flex-wrap: wrap;
  } 
  span {
    text-decoration: underline;
    color: #00f;
    margin-right: 10px;
  }
}
</style>