<template>
  <div class="error">
    <visual />
    <p class="message" v-html="errorMessage" />
    <div class="action-buttons">
      <action-button
        @click="goToTop()"
        class="-top"
      >
        <span v-html="$t('common009')" />
      </action-button>
      <action-button
        @click="goToOfficialSite()"
        class="-official"
      >
        <span v-html="$t('result005')" />
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Visual from '@/components/global/visual.vue'
import ActionButton from '@/components/global/button.vue'

export default {
  name: 'Error',

  components: {
    Visual,
    ActionButton
  },

  props: {
    locale: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters('loader', { loaderConfig: 'config' }),

    errorMessage() {
      return this.$route.name === 'Error' ? this.$t('common008') : this.$t('common007')
    }
  },

  mounted () {
    this.$nextTick(async () => {
      await sleep(this.loaderConfig.minDisplayDuration)
      await this.hideLoader()
    })
  },

  methods: {
    ...mapActions('loader', { hideLoader: 'hide' }),
    
    goToTop() {
      this.$router.push({ name: 'Top' })
    },

    goToOfficialSite() {
      window.open('https://fire-emblem-heroes.com/', '_blank')
    },
  }
}
</script>

<i18n lang="yaml" src="@/locales/common.yaml"></i18n>
<i18n lang="yaml" src="@/locales/result.yaml"></i18n>

<style lang="scss" scoped>
.error {
  padding-bottom: 70px;

  > .message {
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 6px;
    font-size: $fs-base * 1.7;
    text-align: center;
  }
  > .action-buttons {
    margin-top: 67px;
  }
}
  .action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      width: 600px;
      line-height: lh(38, $fs-large);
    }
    .button.-top {
      font-size: $fs-large;
    }
    .button.-official {
      $this: #{&};
      white-space: nowrap;
      font-size: $fs-large;
      line-height: lh(38, $fs-large);

      @at-root {
        #app {
          &.-es-es,
          &.-fr-ca,
          &.-fr-fr {
            #{$this} {
              font-size: $fs-middle;
            }        
          }
          &.-es-mx {
            #{$this} {
              font-size: $fs-xsmall;
            }        
          }
          &.-pt-br {
            #{$this} {
              font-size: $fs-small;
            }        
          }
        }
      }

      .inner {
        margin-left: -76px;
        margin-right: -76px;
      }
    }
    .button + .button {
      margin-top: 24px;
    }
  }
</style>
