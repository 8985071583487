<template>
  <div class="debug">
    <debug />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Debug from '@/components/debug.vue'

export default {
  name: 'DebugLink',

  components: {
    Debug,
  },

  props: {
    locale: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters('loader', { loaderConfig: 'config' }),
  },

  mounted () {
    this.$nextTick(async () => {
      await sleep(this.loaderConfig.minDisplayDuration)
      await this.hideLoader()
    })
  },

  methods: {
    ...mapActions('loader', { hideLoader: 'hide' }),
  }
}
</script>

<style lang="scss" scoped>
</style>